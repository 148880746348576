import React from "react";
import Sidebar from "./../../components/sidebar";
import Header from "./../../components/Header";
import AddThProd from "../../components/AWebsite/ThreeProduct/AddThProd";



const AddTHProductScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddThProd />
      </main>
    </>
  );
};

export default AddTHProductScreen;
