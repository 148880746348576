import React from "react";

const ProductsStatistics = () => {
  return (
    <div className="col-xl-6 col-lg-12" id="hm">
      <div className="card mb-4 " id="hm">
        <article className="card-body" id="hm">
          <h5 className="card-title" id="kl">Products statistics</h5>
          <iframe
            style={{
              background: "#465884",
              border: "none",
              borderRadius: "2px",
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
              width: "100%",
              height: "350px",
            }}
            title="Product stat"
            src="https://charts.mongodb.com/charts-veedash-uuyok/embed/charts?id=6531cb73-1331-400d-8cf5-5d83440ec9a4&maxDataAge=1800&theme=dark&autoRefresh=true"
          ></iframe>
          
        </article>
      </div>
    </div>
  );
};

export default ProductsStatistics;




