// Import necessary dependencies and constants
import axios from "axios";
import {
  FRESTAU_CREATE_FAIL,
  FRESTAU_CREATE_REQUEST,
  FRESTAU_CREATE_SUCCESS,
  FRESTAU_DELETE_FAIL,
  FRESTAU_DELETE_REQUEST,
  FRESTAU_DELETE_SUCCESS,
  FRESTAU_LIST_FAIL,
  FRESTAU_LIST_REQUEST,
  FRESTAU_LIST_SUCCESS,
} from "../Constants/ResFoodConstants";
import { logout } from "./userActions";


// List RestausFood action
export const listRestausFood = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FRESTAU_LIST_REQUEST });

    

    const { data } = await axios.get(`https://frontside.veetoore.store/api/restausfood/all`);

    dispatch({ type: FRESTAU_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: FRESTAU_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE RESTAU
export const deleteRestausFood = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FRESTAU_DELETE_REQUEST });

    

    await axios.delete(`https://frontside.veetoore.store/api/restausfood/${id}`);

    dispatch({ type: FRESTAU_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: FRESTAU_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE RESTAU
export const createRestausFood =
  (resname, foname, fodescription, filename, foprice) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FRESTAU_CREATE_REQUEST });

      

      const requestData = {
        resname,
        foname,
        fodescription,
        filename,
        foprice,
      };

      const response = await axios.post(
        `https://frontside.veetoore.store/api/restausfood/`,
        requestData,
        
      );

      dispatch({ type: FRESTAU_CREATE_SUCCESS, payload: response.data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized") {
        dispatch(logout());
      }
      dispatch({
        type: FRESTAU_CREATE_FAIL,
        payload: message,
      });
    }
  };
