import React from "react";
import { Link, NavLink } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import "../App.css";

const Sidebar = () => {
  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link to="/" className="brand-wrap">
            <img
              src="/images/icon.png"
              style={{ height: "6" }}
              className="logo"
              id="lg"
              alt="Ecommerce dashboard template"
            />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize" >
              <i className="text-muted fas fa-stream" id="bb"></i>
            </button>
          </div>
        </div>

        <nav>
          <ul className="menu-aside">
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/"
                exact="true"
              >
                <i className="icon fas fa-home"></i>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>


            <span className="textt">Market VeeDash Product</span>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/products"
              >
                <i className="icon fas fa-shopping-bag"></i>
                <span className="text"> Products</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/addproduct"
              >
                <i className="icon fas fa-cart-plus"></i>
                <span className="text">Add product</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/category"
              >
                <i className="icon fas fa-list"></i>
                <span className="text"> Categories</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/orders"
              >
                <i className="icon fas fa-bags-shopping"></i>
                <span className="text">Orders</span>
              </NavLink>
            </li>


            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/users"
              >
                <i className="icon far fa-address-card"></i>
                <span className="text"> VeeDash Empl</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/bdproduct"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text">WEB Product</span>
              </NavLink>
            </li>


            <span className="textt">Food VeeDash</span>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/restaus"
              >


                <i className="icon fas fa-utensils"></i>
                <span className="text"> Restaurants</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/restausfood"
              >
                <i className="icon fas fa-pizza-slice"></i>
                <span className="text">Restaurants Food</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/categor"
              >
                <i className="icon far fa-file-alt"></i>
                <span className="text">  Contract</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/addrestau"
              >
                <i className="icon fa fa-cloud-upload"></i>
                <span className="text">Add Restaurants</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/addrestfood"
              >

                <i className="icon fa fa-plus-circle" ></i>
                <span className="text">Add Restaurants Food</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/addrestfoodhh"
              >
                <i className="icon fas fa-receipt"></i>
                <span className="text">  Food Orders</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/user"
              >
                <i className="icon fas fa-kiss-wink-heart"></i>
                <span className="text">Best Client</span>
              </NavLink>
            </li>




            <span className="textt">Veetoor Market</span>




            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/vtproducts"
              >
                <i className="icon fa fa-vimeo"></i>

                <span className="text">VT_Product</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link"
                to="/vtaddproduct"
              >
                <i className="icon fa fa-cloud-upload"></i>
                <span className="text">VT_AddProduct</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link disabled"
                to="/selle"
              >
                <i className="icon  fas fa-usd-circle"></i>
                <span className="text">  -VT_BestDeals</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link disabled"
                to="/addprodu"
              >
                <i className="icon fa fa-first-order"></i>
                <span className="text"> VT_Order_History</span>
              </NavLink>
            </li>


            <span className="textt">Client Facing</span>



            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/sellrs"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text">Sellers</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/addprouct"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text"> Transactions</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/slers"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text">Best Client</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link disabled"
                to="/addroduct"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text"> Transactions</span>
              </NavLink>
            </li>


            <span className="textt">Website Facing ADD</span>



            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/addsrvice"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text">Add Services</span>
              </NavLink>
            </li>



            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/thproduct"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text"> Three Prod</span>
              </NavLink>
            </li>


            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/addplbdprod"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text"> Web Plusprod </span>
              </NavLink>
            </li>



            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/addbdproduct"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text"> Best Deals</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                activeclassname="active"
                className="menu-link "
                to="/wdproduct"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text"> Welcome Deals</span>
              </NavLink>
            </li>


          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
