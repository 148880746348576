import {
    FRESTAU_CREATE_FAIL,
    FRESTAU_CREATE_REQUEST,
    FRESTAU_CREATE_RESET,
    FRESTAU_CREATE_SUCCESS,
    FRESTAU_DELETE_FAIL,
    FRESTAU_DELETE_REQUEST,
    FRESTAU_DELETE_SUCCESS,
    FRESTAU_LIST_FAIL,
    FRESTAU_LIST_REQUEST,
    FRESTAU_LIST_SUCCESS,
  } from "../Constants/ResFoodConstants";
  
  // ALL RESTAUS
  export const restaufoodListReducer = (state = { restausfood: [] }, action) => {
    switch (action.type) {
      case FRESTAU_LIST_REQUEST:
        return { loading: true, restausfood: [] };
      case FRESTAU_LIST_SUCCESS:
        return { loading: false, restausfood: action.payload };
      case FRESTAU_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE RESTAU
  export const restaufoodDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case FRESTAU_DELETE_REQUEST:
        return { loading: true };
      case FRESTAU_DELETE_SUCCESS:
        return { loading: false, success: true };
      case FRESTAU_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // CREATe RESTAU
  export const restaufoodCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case FRESTAU_CREATE_REQUEST:
        return { loading: true };
      case FRESTAU_CREATE_SUCCESS:
        return { loading: false, success: true, restausfood: action.payload };
      case FRESTAU_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case FRESTAU_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT RESTAU





