export const WDPROD_LIST_REQUEST = "WDPROD_LIST_REQUEST";
export const WDPROD_LIST_SUCCESS = "WDPROD_LIST_SUCCESS";
export const WDPROD_LIST_FAIL = "WDPROD_LIST_FAIL";

export const WDPROD_DELETE_REQUEST = "WDPROD_DELETE_REQUEST";
export const WDPROD_DELETE_SUCCESS = "WDPROD_DELETE_SUCCESS";
export const WDPROD_DELETE_FAIL = "WDPROD_DELETE_FAIL";

export const WDPROD_CREATE_REQUEST = "WDPROD_CREATE_REQUEST";
export const WDPROD_CREATE_SUCCESS = "WDPROD_CREATE_SUCCESS";
export const WDPROD_CREATE_FAIL = "WDPROD_CREATE_FAIL";
export const WDPROD_CREATE_RESET = "WDPROD_CREATE_RESET";





