import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../../App.css";
import Message from "../../../components/LoadingError/Error";
import Loading from "../../../components/LoadingError/Loading";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../components/LoadingError/Toast";
import { toast } from "react-toastify";
import { SERVIC_CREATE_RESET } from "../../../Redux/Constants/WebConstants/ServiceCanstat";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AServices = () => {

  const [sname, setSName] = useState("");
  const [file, setFile] = useState("");

  const navigate = useNavigate();





  const setimgfile = (e) => {
      setFile(e.target.files[0])
  }




  const dispatch = useDispatch();

  const serviceCreate = useSelector((state) => state.serviceCreate);
  const { loading, error, service } = serviceCreate;


  useEffect(() => {
      if (service) {
        toast.success('service Added', ToastObjects);
          dispatch({ type: SERVIC_CREATE_RESET });
          // Clear all form fields
          setSName('');
          setFile(null); // Clear the file input
      }
  }, [service, dispatch]);




  // adduser data

  const addUserData = async (e) => {
      e.preventDefault();

      var formData = new FormData();
      formData.append("photo", file);
      formData.append("sname", sname);





      const config = {
          headers: {
              "Content-Type": "multipart/form-data"
          }
      }

      try {
        const res = await axios.post("https://frontside.veetoore.store/AServices", formData, config);
      
          if (res.data.status === 401 || !res.data) {
            console.log("error");
          } else {
            toast.success('NEW SERVICE Added', ToastObjects);
            dispatch({ type: SERVIC_CREATE_RESET });
            // Clear all form fields
            setSName('');
            setFile(null); // Clear the file input 
      
            // Check if navigate is available before using it
            if (navigate) {
              navigate('/addsrvice');
            }
          } 
        } catch (error) {
          console.error(error);
        }
     
  }



  
  return (
    <>
      <Toast />

      <div className="container mt-3">
        <form onSubmit={addUserData}>
          <Link to="/bdproduct" className="btn btn-danger text-white right">
            Go to All Web Deals
          </Link>
          <h2 className="content-title" id="fb">
            Add Veetoor Service
          </h2>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm" id="cont">
                <div className="card-bodyf">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="test">Service name</Form.Label>
                      <Form.Control
                        type="text"
                        name="pricee"
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={sname}
                        onChange={(e) => setSName(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className="test">
                        Select Service Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="photo"
                        placeholder="Exaple( PNG ou JPEG )"
                        className="nobb"
                        onChange={setimgfile}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={addUserData}
                    >
                      Submit
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AServices;
