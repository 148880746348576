import {
    RESTAU_CREATE_FAIL,
    RESTAU_CREATE_REQUEST,
    RESTAU_CREATE_RESET,
    RESTAU_CREATE_SUCCESS,
    RESTAU_DELETE_FAIL,
    RESTAU_DELETE_REQUEST,
    RESTAU_DELETE_SUCCESS,
    RESTAU_EDIT_FAIL,
    RESTAU_EDIT_REQUEST,
    RESTAU_EDIT_SUCCESS,
    RESTAU_LIST_FAIL,
    RESTAU_LIST_REQUEST,
    RESTAU_LIST_SUCCESS,
    RESTAU_UPDATE_FAIL,
    RESTAU_UPDATE_REQUEST,
    RESTAU_UPDATE_RESET,
    RESTAU_UPDATE_SUCCESS,
  } from "../Constants/RestoConstants";
  
  // ALL RESTAUS
  export const restauListReducer = (state = { restaus: [] }, action) => {
    switch (action.type) {
      case RESTAU_LIST_REQUEST:
        return { loading: true, restaus: [] };
      case RESTAU_LIST_SUCCESS:
        return { loading: false, restaus: action.payload };
      case RESTAU_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE RESTAU
  export const restauDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case RESTAU_DELETE_REQUEST:
        return { loading: true };
      case RESTAU_DELETE_SUCCESS:
        return { loading: false, success: true };
      case RESTAU_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // CREATe RESTAU
  export const restauCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case RESTAU_CREATE_REQUEST:
        return { loading: true };
      case RESTAU_CREATE_SUCCESS:
        return { loading: false, success: true, restaus: action.payload };
      case RESTAU_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case RESTAU_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT RESTAU
  export const restauEditReducer = (
    state = { restaus: { reviews: [] } },
    action
  ) => {
    switch (action.type) {
      case RESTAU_EDIT_REQUEST:
        return { ...state, loading: true };
      case RESTAU_EDIT_SUCCESS:
        return { loading: false, restaus: action.payload };
      case RESTAU_EDIT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // UPDATE RESTAU
  export const restauUpdateReducer = (state = { restaus: {} }, action) => {
    switch (action.type) {
      case RESTAU_UPDATE_REQUEST:
        return { loading: true };
      case RESTAU_UPDATE_SUCCESS:
        return { loading: false, success: true, restaus: action.payload };
      case RESTAU_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case RESTAU_UPDATE_RESET:
        return { restau: {} };
      default:
        return state;
    }
  };
  




