import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import MainVTProducts from "../components/VTP/VTmainProduct";

const VTProductScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainVTProducts />
      </main>
    </>
  );
};

export default VTProductScreen;
