// PrivateRoute.js
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { userInfo, loading, error } = useSelector((state) => state.userLogin);

  if (loading) {
    // Loading state, you may show a loading spinner or message
    return <div>Loading...</div>;
  }

  if (error) {
    // Handle authentication error (e.g., redirect to login)
    console.error("Authentication error:", error);
    return <Navigate to="/login" replace />;
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return userInfo && userInfo.isAdmin ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
