import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../App.css';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../LoadingError/Toast';
import { PRODUCT_CREATE_RESET } from '../../Redux/Constants/ProductConstants';
import { toast } from 'react-toastify';

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
const Register = () => {

  const [codeart, setCODEART] = useState("");

  const [ean, setEAN] = useState("");

  const [lbldept, setLBLDEPT] = useState("");

  const [lblray, setLBLRAY] = useState("");

  const [lblfam, setLBLFAM] = useState("");

  const [ubison, setUbison] = useState("");

  const [lblssfam, setLBLSSFAM] = useState("");

  const [lblartwynd, setLIBART_WYND] = useState("");

  const [price, setprice] = useState("");

  const [file, setFile] = useState("");


  const navigate = useNavigate();





  const setimgfile = (e) => {
    setFile(e.target.files[0])
  }




  const dispatch = useDispatch();

  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product } = productCreate;


  useEffect(() => {
    if (product) {
      toast.success('Product Added', ToastObjects);
      dispatch({ type: PRODUCT_CREATE_RESET });
      // Clear all form fields
      setCODEART('');
      setEAN('');
      setLBLDEPT('');
      setLBLRAY('');
      setLBLFAM('');
      setUbison('');
      setLBLSSFAM('');
      setLIBART_WYND('');
      setprice('');
      setFile(null); // Clear the file input
    }
  }, [product, dispatch]);




  // adduser data

  const addUserData = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("photo", file);
    formData.append("codeart", codeart);
    formData.append("ean", ean);
    formData.append("lbldept", lbldept);
    formData.append("lblray", lblray);
    formData.append("lblfam", lblfam);
    formData.append("ubison", ubison);
    formData.append("lblssfam", lblssfam);
    formData.append("lblartwynd", lblartwynd);
    formData.append("price", price);





    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    try {
      const res = await axios.post("https://frontside.veetoore.store/register", formData, config);
  
      if (res.data.status === 401 || !res.data) {
        console.log("error");
      } else {
        toast.success('Product Added', ToastObjects);
        dispatch({ type: PRODUCT_CREATE_RESET });
        // Clear all form fields
        setCODEART('');
        setEAN('');
        setLBLDEPT('');
        setLBLRAY('');
        setLBLFAM('');
        setUbison('');
        setLBLSSFAM('');
        setLIBART_WYND('');
        setprice('');
        setFile(null); // Clear the file input 
  
        // Check if navigate is available before using it
        if (navigate) {
          navigate('/addproduct');
        }
      } 
    } catch (error) {
      console.error(error);
    }
  }
 
  return (
    <>
      <Toast />

      <div className="container mt-3">
        <form onSubmit={addUserData}>
          <Link to="/products" className="btn btn-danger text-white right">
            Go to products
          </Link>
          <h2 className="content-title" id="fb">
            Add product
          </h2>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm" id="cont">
                <div className="card-bodyf">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                    <Form.Group className="mb-3" controlId="formBasicCODEART">
                      <Form.Label className="test">CODEART</Form.Label>
                      <Form.Control
                        type="text"
                        name="CODEART"
                        placeholder="Exaple( 1962512 )"
                        className="nobb"
                        value={codeart}
                        onChange={(e) => setCODEART(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEAN">
                      <Form.Label className="test">Product EAN</Form.Label>
                      <Form.Control
                        type="text"
                        name="EAN"
                        placeholder="Exaple( 4017100127625 )"
                        className="nobb"
                        value={ean}
                        onChange={(e) => setEAN(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLBLDEPT">
                      <Form.Label className="test">Product LBLDEPT</Form.Label>
                      <Form.Control
                        type="text"
                        name="LBLDEPT"
                        placeholder="Exaple( PGC )"
                        className="nobb"
                        value={lbldept}
                        onChange={(e) => setLBLDEPT(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLBLRAY">
                      <Form.Label className="test">Product LBLRAY</Form.Label>
                      <Form.Control
                        type="text"
                        name="LBLRAY"
                        placeholder="Exaple( BISCUITERIE )"
                        className="nobb"
                        value={lblray}
                        onChange={(e) => setLBLRAY(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLBLFAM">
                      <Form.Label className="test">Product LBLFAM</Form.Label>
                      <Form.Control
                        type="text"
                        name="LBLFAM"
                        placeholder="Exaple( GATEAUX-DESSERT )"
                        className="nobb"
                        value={lblfam}
                        onChange={(e) => setLBLFAM(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLBLSSFAM">
                      <Form.Label className="test">Product LBLSSFAM</Form.Label>
                      <Form.Control
                        type="text"
                        name="LBLSSFAM"
                        placeholder="Exaple( BISCUITS FOURRES )"
                        className="nobb"
                        value={lblssfam}
                        onChange={(e) => setLBLSSFAM(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicubison">
                      <Form.Label className="test">Product ubison </Form.Label>
                      <Form.Control
                        type="text"
                        name="ubison"
                        placeholder="Exaple( BISCUITERIE )"
                        className="nobb"
                        value={ubison}
                        onChange={(e) => setUbison(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLIBARTWYND">
                      <Form.Label className="test">
                        Product LIBART_WYND
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="LIBARTWYND"
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={lblartwynd}
                        onChange={(e) => setLIBART_WYND(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicprice">
                      <Form.Label className="test">Product price</Form.Label>
                      <Form.Control
                        type="text"
                        name="price"
                        placeholder="Exaple( 5,40 )"
                        className="nobb"
                        value={price}
                        onChange={(e) => setprice(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicImage">
                      <Form.Label className="test">
                        Select Product Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="photo"
                        placeholder="Exaple( PNG ou JPEG )"
                        className="nobb"
                        onChange={setimgfile}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={addUserData}
                    >
                      Submit
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
