// Import necessary dependencies and constants
import axios from "axios";
import {
  BDPROD_CREATE_FAIL,
  BDPROD_CREATE_REQUEST,
  BDPROD_CREATE_SUCCESS,
  BDPROD_DELETE_FAIL,
  BDPROD_DELETE_REQUEST,
  BDPROD_DELETE_SUCCESS,
  BDPROD_LIST_FAIL,
  BDPROD_LIST_REQUEST,
  BDPROD_LIST_SUCCESS,
} from "../../Constants/WebConstants/BDproductConstant";
import { logout } from "../userActions";

// List BDProducts action
export const listBDproduct = () => async (dispatch) => {
  try {
    dispatch({ type: BDPROD_LIST_REQUEST });

    

    const { data } = await axios.get(`https://frontside.veetoore.store/api/bestdealp/all`);

    dispatch({ type: BDPROD_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: BDPROD_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE BDProduct
export const deleteBDproduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: BDPROD_DELETE_REQUEST });

    

    await axios.delete(`https://frontside.veetoore.store/api/bestdealp/${id}`);

    dispatch({ type: BDPROD_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: BDPROD_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE BDProduct
export const createBDproduct = (bdname, bdescription, filename) => async (dispatch) => {
  try {
    dispatch({ type: BDPROD_CREATE_REQUEST });

    

    const requestData = {
      bdname,
      bdescription,
      filename,
    };

    const response = await axios.post(`https://frontside.veetoore.store/api/bestdealp/`, requestData);

    dispatch({ type: BDPROD_CREATE_SUCCESS, payload: response.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: BDPROD_CREATE_FAIL,
      payload: message,
    });
  }
};
