import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteBDproduct } from "../../../Redux/Actions/WebAction/BDproductAction";


const BDproduc = (props) => {
  const { bdproduct } = props;
  const dispatch = useDispatch();

  const deletehandler = (id) => {
    if (window.confirm("Are you sure??")) {
      dispatch(deleteBDproduct(id));
    }
  };

  return (
    <>
    
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5" >
        <div className="card card-product-grid shadow-sm" id="bd">
          <Link to="#" className="img-wrap">
   <img src={`https://frontside.veetoore.store/ubestd/${bdproduct.imagepath}`} alt="restau" />

          </Link>
          <div className="info-wrap">
            <Link to="#" className="title text-truncate">
              {bdproduct.bdname}
            </Link>
            <div className="price mb-2">{bdproduct.bdescription} MAD</div>
            <div className="row">
               
              <Link
                to="#"
                onClick={() => deletehandler(bdproduct._id)}
                className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default BDproduc;
