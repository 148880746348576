export const THPROD_LIST_REQUEST = "THPROD_LIST_REQUEST";
export const THPROD_LIST_SUCCESS = "THPROD_LIST_SUCCESS";
export const THPROD_LIST_FAIL = "THPROD_LIST_FAIL";

export const THPROD_DELETE_REQUEST = "THPROD_DELETE_REQUEST";
export const THPROD_DELETE_SUCCESS = "THPROD_DELETE_SUCCESS";
export const THPROD_DELETE_FAIL = "THPROD_DELETE_FAIL";

export const THPROD_CREATE_REQUEST = "THPROD_CREATE_REQUEST";
export const THPROD_CREATE_SUCCESS = "THPROD_CREATE_SUCCESS";
export const THPROD_CREATE_FAIL = "THPROD_CREATE_FAIL";
export const THPROD_CREATE_RESET = "THPROD_CREATE_RESET";





