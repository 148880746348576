import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../App.css';
import Message from '../../components/LoadingError/Error';
import Loading from '../../components/LoadingError/Loading';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../components/LoadingError/Toast';

import { RESTAU_CREATE_RESET } from '../../Redux/Constants/RestoConstants';
import { toast } from 'react-toastify';

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};


const AddRest = () => {

  const [resname, setResName] = useState("");
  
  const [resdescription, setResDescription] = useState("");


  const [rescateg, setResCateg] = useState("");

  const [reslocalisation, setResLocalisation] = useState("");

  const [file, setResFile] = useState("");


  const navigate = useNavigate();
 
 



  const setRimgfile = (e) => {
    setResFile(e.target.files[0])  
  }

 


  const dispatch = useDispatch();

  const restauCreate = useSelector((state) => state.restauCreate);
  const { loading, error, restaus } = restauCreate;


  useEffect(() => {
    if (restaus) {
      toast.success('restau Added', ToastObjects);
      dispatch({ type: RESTAU_CREATE_RESET });
      // Clear all form fields
      setResName('');
      setResDescription('');
      setResCateg('');
      setResLocalisation('');
      setResFile(null); // Clear the file input
    }
  }, [restaus, dispatch]);




  // adduser data

  const addUserData = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("photo", file);
    formData.append("resname", resname);
    formData.append("resdescription", resdescription);
    formData.append("rescateg", rescateg);
    formData.append("reslocalisation", reslocalisation);

    
    

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }


    try {
      const res = await axios.post("https://frontside.veetoore.store/AddRest", formData, config);
    
        if (res.data.status === 401 || !res.data) {
          console.log("error");
        } else {
          toast.success('restau Added', ToastObjects);
        dispatch({ type: RESTAU_CREATE_RESET });
        // Clear all form fields
        setResName('');
        setResDescription('');
        setResCateg('');
        setResLocalisation('');
        setResFile(null); // Clear the file input 
    
          // Check if navigate is available before using it
          if (navigate) {
            navigate('/Addrestau');
          }
        } 
      } catch (error) {
        console.error(error);
      }
  } 

  

  return (
    <>
      <Toast />
    
      <div className="container mt-3"  >
      <form onSubmit={addUserData}>
      <Link to="/restaus" className="btn btn-danger text-white right">
              Go to restaus
            </Link>
            <h2 className="content-title" id='fb'>Add NEW Restaurants</h2>
            
              
        <div className="row mb-4">
            <div className="col-xl-8 col-lg-8" >
              <div className="card mb-4 shadow-sm" id='cont'>
                <div className="card-bodyf" >
                {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
        


                      <Form.Group className="mb-3" controlId="formBasicname">
                        <Form.Label className="test">RESTAURANT NAME</Form.Label>
                        <Form.Control
                          type="text"
                          name='fname'
                          placeholder="Exaple( 1962512 )"
                          className="nobb"
                          value={resname}
                          onChange={(e) => setResName(e.target.value)}
                          autoComplete="off"
                        />
                      </Form.Group>

                      


                      <Form.Group className="mb-3" controlId="formBasicCateg">
                        <Form.Label className="test">RESTAURANT CATEGORIE</Form.Label>
                        <Form.Control
                          type="text"
                          name='catego'
                          placeholder="Exaple( par défaut )"
                          className="nobb"
                          value={rescateg}
                          onChange={(e) => setResCateg(e.target.value)}
                          autoComplete="off"

                        />
                      </Form.Group>



                      <Form.Group className="mb-3" controlId="formBasicLocal">
                        <Form.Label className="test">RESTAURANT LOCALISATION</Form.Label>
                        <Form.Control
                          type="text"
                          name='localis'
                          placeholder="Exaple( 4017100127625 )"
                          className="nobb"
                          value={reslocalisation}
                          onChange={(e) => setResLocalisation(e.target.value)}
                          autoComplete="off"
                        />
                      </Form.Group>
 

                      <Form.Group className="mb-3" controlId="formBasicDisc">
                        <Form.Label className="test">RESTAURANT DISCRIPTION</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={3}
                          name='disc'
                          placeholder="Exaple( 5,40 )"
                          className="nobb"
                          value={resdescription}
                          onChange={(e) => setResDescription(e.target.value)}
                          autoComplete="off"
                        />
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="formBasicIMG">
                        <Form.Label className="test">SELECT RESTAURANT IMG</Form.Label>
                        <Form.Control
                          type="file"
                          name='photo'
                          placeholder="Exaple( PNG ou JPEG )"
                          className="nobb"
                          onChange={setRimgfile}
                          autoComplete="off"
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit" onClick={addUserData}>
                        Submit
                      </Button>
                   



          </div>
              </div>
            </div>
          </div>
          </form>
      </div>
    </>
  )
}

export default AddRest;