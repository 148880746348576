import React from "react";
import Sidebar from "./../../components/sidebar";
import Header from "./../../components/Header";
import AddServices from "../../components/AWebsite/services/AddServic";

const AddSRVScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddServices />
      </main>
    </>
  );
};

export default AddSRVScreen;
