
import React from "react";
import Sidebar from "./../../components/sidebar";
import Header from "./../../components/Header";
import AddWDProd from "../../components/AWebsite/WelcomDeals/AddWDproduct";



const AddWDProductScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddWDProd />
      </main>
    </>
  );
};

export default AddWDProductScreen;
