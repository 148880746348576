import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userListReducer, userLoginReducer } from "./Reducers/userReducers";
import {
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
} from "./Reducers/ProductReducers";
import {
  orderDeliveredReducer,
  orderDetailsReducer,
  orderListReducer,
} from "./Reducers/OrderReducres";

import {
  foodorderDeliveredReducer,
  foodorderDetailsReducer,
  foodorderListReducer,
} from "./Reducers/FoodOrderReducer";

import {
  restauListReducer,
  restauDeleteReducer,
  restauCreateReducer,
  } from "./Reducers/RestoReducres"

  import {
    restaufoodCreateReducer,
    restaufoodListReducer,
    restaufoodDeleteReducer
  } from "./Reducers/ResFoodReducers"

import {
  vtproductListReducer,
  vtproductDeleteReducer,
  vtproductCreateReducer,
  vtproductEditReducer,
  vtproductUpdateReducer
} from "./Reducers/VTProductReducer"

import {
  servicesCreateReducer,
  servicesListReducer,
  servicesDeleteReducer,
} from "./Reducers/WebReducers/ServiceReducer"
import {
  bdproductCreateReducer,
  bdproductListReducer,
  bdproductDeleteReducer,
} from "./Reducers/WebReducers/BDproductReducer"

import {
  plbdproductCreateReducer,
  plbdproductListReducer,
  plbdproductDeleteReducer,

} from "./Reducers/WebReducers/PLBDprodReducer"

import {
  thproductCreateReducer,
  thproductListReducer,
  thproductDeleteReducer,
} from "./Reducers/WebReducers/ThreeProductReducer"

import {
  wdproductCreateReducer,
  wdproductListReducer,
  wdproductDeleteReducer,
} from "./Reducers/WebReducers/WDproductReducer"



const reducer = combineReducers({
  userLogin: userLoginReducer,
  userList: userListReducer,
  productList: productListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  orderDeliver: orderDeliveredReducer,

  foodorderList: foodorderListReducer,
  foodorderDetails: foodorderDetailsReducer,
  foodorderDeliver: foodorderDeliveredReducer,

  restauCreate: restauCreateReducer,
  restauList:restauListReducer,
  restauDelete:restauDeleteReducer,

  restaufoodCreate:restaufoodCreateReducer,
  restaufoodList:restaufoodListReducer,
  restaufoodDelete:restaufoodDeleteReducer,

  
  vtproductList: vtproductListReducer,
  vtproductDelete: vtproductDeleteReducer,
  vtproductCreate: vtproductCreateReducer,
  vtproductEdit: vtproductEditReducer,
  vtproductUpdate: vtproductUpdateReducer,




//website 


serviceCreate: servicesCreateReducer,
serviceList: servicesListReducer,
serviceDelete: servicesDeleteReducer,


bdproductCreate: bdproductCreateReducer,
bdproductList: bdproductListReducer,
bdproductDelete: bdproductDeleteReducer,


plbdproductCreate: plbdproductCreateReducer,
plbdproductList: plbdproductListReducer,
plbdproductDelete: plbdproductDeleteReducer,


thproductCreate: thproductCreateReducer,
thproductList: thproductListReducer,
thproductDelete: thproductDeleteReducer,



wdproductCreate: wdproductCreateReducer,
wdproductList: wdproductListReducer,
wdproductDelete: wdproductDeleteReducer,

});

// login
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromLocalStorage },
};


const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
