
import {
    SERVIC_CREATE_FAIL,
    SERVIC_CREATE_REQUEST,
    SERVIC_CREATE_RESET,
    SERVIC_CREATE_SUCCESS,
    SERVIC_DELETE_FAIL,
    SERVIC_DELETE_REQUEST,
    SERVIC_DELETE_SUCCESS,
    SERVIC_LIST_FAIL,
    SERVIC_LIST_REQUEST,
    SERVIC_LIST_SUCCESS,
  } from "../../Constants/WebConstants/ServiceCanstat";
  
  // ALL VTPRODUCTS
  export const servicesListReducer = (state = { services: [] }, action) => {
    switch (action.type) {
      case SERVIC_LIST_REQUEST:
        return { loading: true, services: [] };
      case SERVIC_LIST_SUCCESS:
        return { loading: false, services: action.payload };
      case SERVIC_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE VTPRODUCT
  export const servicesDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SERVIC_DELETE_REQUEST:
        return { loading: true };
      case SERVIC_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SERVIC_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE VTPRODUCT
  export const servicesCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case SERVIC_CREATE_REQUEST:
        return { loading: true };
      case SERVIC_CREATE_SUCCESS:
        return { loading: false, success: true, services: action.payload };
      case SERVIC_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case SERVIC_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  