export const PLBDPROD_LIST_REQUEST = "PLBDPROD_LIST_REQUEST";
export const PLBDPROD_LIST_SUCCESS = "PLBDPROD_LIST_SUCCESS";
export const PLBDPROD_LIST_FAIL = "PLBDPROD_LIST_FAIL";

export const PLBDPROD_DELETE_REQUEST = "PLBDPROD_DELETE_REQUEST";
export const PLBDPROD_DELETE_SUCCESS = "PLBDPROD_DELETE_SUCCESS";
export const PLBDPROD_DELETE_FAIL = "PLBDPROD_DELETE_FAIL";

export const PLBDPROD_CREATE_REQUEST = "PLBDPROD_CREATE_REQUEST";
export const PLBDPROD_CREATE_SUCCESS = "PLBDPROD_CREATE_SUCCESS";
export const PLBDPROD_CREATE_FAIL = "PLBDPROD_CREATE_FAIL";
export const PLBDPROD_CREATE_RESET = "PLBDPROD_CREATE_RESET";





