// Import necessary dependencies and constants
import axios from "axios";
import {
  RESTAU_CREATE_FAIL,
  RESTAU_CREATE_REQUEST,
  RESTAU_CREATE_SUCCESS,
  RESTAU_DELETE_FAIL,
  RESTAU_DELETE_REQUEST,
  RESTAU_DELETE_SUCCESS,
  RESTAU_EDIT_FAIL,
  RESTAU_EDIT_REQUEST,
  RESTAU_EDIT_SUCCESS,
  RESTAU_LIST_FAIL,
  RESTAU_LIST_REQUEST,
  RESTAU_LIST_SUCCESS,
  RESTAU_UPDATE_FAIL,
  RESTAU_UPDATE_REQUEST,
  RESTAU_UPDATE_SUCCESS,
} from "../Constants/RestoConstants";
import { logout } from "./userActions";


// List Restaus action
export const listRestaus = () => async (dispatch, getState) => {
  try {
    dispatch({ type: RESTAU_LIST_REQUEST });

    

    const { data } = await axios.get(`https://frontside.veetoore.store/api/restaus/all`);

    dispatch({ type: RESTAU_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: RESTAU_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE RESTAU
export const deleteRetau = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESTAU_DELETE_REQUEST });

    

    await axios.delete(`https://frontside.veetoore.store/api/restaus/${id}`);

    dispatch({ type: RESTAU_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: RESTAU_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE RESTAU
export const createRestau =
  (resname, resdescription, rescateg, filename, reslocalisation) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: RESTAU_CREATE_REQUEST });

      

      const { data } = await axios.post(
        `https://frontside.veetoore.store/api/restaus/`,
        { resname, resdescription, rescateg, filename, reslocalisation },
      );

      dispatch({ type: RESTAU_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized") {
        dispatch(logout());
      }
      dispatch({
        type: RESTAU_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT RESTAU
export const editRestau = (id) => async (dispatch) => {
  try {
    dispatch({ type: RESTAU_EDIT_REQUEST });
    
    const { data } = await axios.get(`https://frontside.veetoore.store/api/restaus/${id}`);
    dispatch({ type: RESTAU_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: RESTAU_EDIT_FAIL,
      payload: message,
    });
  }
};

// UPDATE RESTAU
export const updateRestau = (restau) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESTAU_UPDATE_REQUEST });

    

    const { data } = await axios.put(
      `https://frontside.veetoore.store/api/restaus/${restau._id}`,
      restau,
      
    );

    dispatch({ type: RESTAU_UPDATE_SUCCESS, payload: data });
    dispatch({ type: RESTAU_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: RESTAU_UPDATE_FAIL,
      payload: message,
    });
  }
};
