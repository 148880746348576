import React, { useState, useEffect } from "react";
import Toast from "./../LoadingError/Toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import {
  editProduct,
  updateProduct,
} from "./../../Redux/Actions/ProductActions";
import { PRODUCT_UPDATE_RESET } from "../../Redux/Constants/ProductConstants";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000, 
};

const EditProductMain = (props) => {
  const { productId } = props;
  const [codeart, setCODEART] = useState("");
  const [ean, setEAN] = useState("");
  const [codedept, setCODEDEPT] = useState("");
  const [lbldept, setLBLDEPT] = useState("");
  const [coderay, setCODERAY] = useState("");
  const [lblray, setLBLRAY] = useState("");
  const [codefam, setCODEFAM] = useState("");
  const [lblfam, setLBLFAM] = useState("");
  const [lblfams, setLBLFAMS] = useState("");
  const [codesfam, setCODESFAM] = useState("");
  const [lblssfam, setLBLSSFAM] = useState("");
  const [lblartgold, setLBLART_GOLD] = useState("");
  const [lblartwynd, setLIBART_WYND] = useState("");
  const [libellemarque, setLIBELLE_MARQUE] = useState("");
  const [price, setprice] = useState("");
  const [file, setFile] = useState(""); // Store the file object, not just its name

  const dispatch = useDispatch();
 
  const productEdit = useSelector((state) => state.productEdit);
  const { loading, error, product } = productEdit;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      toast.success("Product Updated", ToastObjects);
    } else {
      if (!product || product._id !== productId) {
        dispatch(editProduct(productId));
      } else {
        // Initialize state variables with product data
        setCODEART(product.codeart || "");
        setEAN(product.ean || "");
        setCODEDEPT(product.codedept || "");
        setLBLDEPT(product.lbldept || "");
        setCODERAY(product.coderay || "");
        setLBLRAY(product.lblray || "");
        setCODEFAM(product.codefam || "");
        setLBLFAM(product.lblfam || "");
        setLBLFAMS(product.lblfams || "");
        setCODESFAM(product.codesfam || "");
        setLBLSSFAM(product.lblssfam || "");
        setLBLART_GOLD(product.lblartgold || "");
        setLIBART_WYND(product.lblartwynd || "");
        setLIBELLE_MARQUE(product.libellemarque || "");
        setprice(product.price || "");
        // You should also handle file input properly, similar to your previous code
      }
    }
  }, [product, dispatch, productId, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    // Create a FormData object to handle file uploads
    const formData = new FormData();
    formData.append('_id', productId);
    formData.append('codeart', codeart);
    formData.append('ean', ean);
    formData.append('codedept', codedept);
    formData.append('lbldept', lbldept);
    formData.append('coderay', coderay);
    formData.append('lblray', lblray);
    formData.append('codefam', codefam);
    formData.append('lblfam', lblfam);
    formData.append('lblfams', lblfams);
    formData.append('codesfam', codesfam);
    formData.append('lblssfam', lblssfam);
    formData.append('lblartgold', lblartgold);
    formData.append('lblartwynd', lblartwynd);
    formData.append('libellemarque', libellemarque);
    formData.append('price', price);
    if (file) {
      formData.append('file', file);
    }
    dispatch(updateProduct(formData));
  };
  
  
  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to products
            </Link>
            <h2 className="content-title">Update Product</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {errorUpdate && (
                    <Message variant="alert-danger">{errorUpdate}</Message>
                  )}
                  {loadingUpdate && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <div className='mt-3' >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">CODEART</Form.Label>
                        <Form.Control
                          type="text"
                          name='fname'
                          placeholder=""
                          className="nobb"
                          value={codeart}
                          onChange={(e) => setCODEART(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product EAN</Form.Label>
                        <Form.Control
                          type="text"
                          name='catego'
                          placeholder=""
                          className="nobb"
                          value={ean}
                          onChange={(e) => setEAN(e.target.value)}
                        />
                      </Form.Group>



                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product CODEDEPT</Form.Label>
                        <Form.Control
                          type="text"
                          name='pricee'
                          placeholder=""
                          className="nobb"
                          value={codedept}
                          onChange={(e) => setCODEDEPT(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LBLDEPT</Form.Label>
                        <Form.Control

                          type="text"

                          name='disce'


                          placeholder=""

                          className="nobb"
                          value={lbldept}
                          onChange={(e) => setLBLDEPT(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product CODERAY</Form.Label>
                        <Form.Control

                          type="text"

                          name='pricee'


                          placeholder=""

                          className="nobb"
                          value={coderay}
                          onChange={(e) => setCODERAY(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LBLRAY</Form.Label>
                        <Form.Control

                          type="text"

                          name='disce'


                          placeholder=""

                          className="nobb"
                          value={lblray}
                          onChange={(e) => setLBLRAY(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product CODEFAM</Form.Label>
                        <Form.Control

                          type="text"

                          name='pricee'


                          placeholder=""

                          className="nobb"
                          value={codefam}
                          onChange={(e) => setCODEFAM(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LBLFAM</Form.Label>
                        <Form.Control

                          type="text"

                          name='disce'


                          placeholder=""

                          className="nobb"
                          value={lblfam}
                          onChange={(e) => setLBLFAM(e.target.value)}
                        />
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product CODESFAM</Form.Label>
                        <Form.Control

                          type="text"

                          name='disce'


                          placeholder=""

                          className="nobb"
                          value={codesfam}
                          onChange={(e) => setCODESFAM(e.target.value)}
                        />
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LBLFAM_1</Form.Label>
                        <Form.Control
                          type="text"
                          name='pricee'
                          placeholder=""
                          className="nobb"
                          value={lblfams}
                          onChange={(e) => setLBLFAMS(e.target.value)}
                        />
                      </Form.Group>



                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LBLSSFAM</Form.Label>
                        <Form.Control
                          type="text"
                          name='pricee'
                          placeholder=""
                          className="nobb"
                          value={lblssfam}
                          onChange={(e) => setLBLSSFAM(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LBLART_GOLD</Form.Label>
                        <Form.Control
                          type="text" name='disce'
                          placeholder=""
                          className="nobb"
                          value={lblartgold}
                          onChange={(e) => setLBLART_GOLD(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LIBART_WYND</Form.Label>
                        <Form.Control
                          type="text"
                          name='pricee'
                          placeholder=""
                          className="nobb"
                          value={lblartwynd}
                          onChange={(e) => setLIBART_WYND(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product LIBELLE_MARQUE</Form.Label>
                        <Form.Control
                          type="text"
                          name='disce'
                          placeholder=""
                          className="nobb"
                          value={libellemarque}
                          onChange={(e) => setLIBELLE_MARQUE(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="test">Product price</Form.Label>
                        <Form.Control
                          type="text"
                          name='catego'
                          placeholder=""
                          className="nobb"
                          value={price}
                          onChange={(e) => setprice(e.target.value)}
                        />
                      </Form.Group>

                      

                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="test">Select Product Image</Form.Label>
                        <Form.Control
                          type="file"
                          name='photo'
                          placeholder=""
                          className="nobb"
                          value={file}
                          onChange={(e) => setFile(e.target.value)}
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit" onClick={submitHandler}>
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditProductMain;


