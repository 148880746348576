export const FRESTAU_LIST_REQUEST = "FRESTAU_LIST_REQUEST";
export const FRESTAU_LIST_SUCCESS = "FRESTAU_LIST_SUCCESS";
export const FRESTAU_LIST_FAIL = "FRESTAU_LIST_FAIL";

export const FRESTAU_DELETE_REQUEST = "FRESTAU_DELETE_REQUEST";
export const FRESTAU_DELETE_SUCCESS = "FRESTAU_DELETE_SUCCESS";
export const FRESTAU_DELETE_FAIL = "FRESTAU_DELETE_FAIL";

export const FRESTAU_CREATE_REQUEST = "FRESTAU_CREATE_REQUEST";
export const FRESTAU_CREATE_SUCCESS = "FRESTAU_CREATE_SUCCESS";
export const FRESTAU_CREATE_FAIL = "FRESTAU_CREATE_FAIL";
export const FRESTAU_CREATE_RESET = "FRESTAU_CREATE_RESET";





