import {
    FOODORDER_DELIVERED_FAIL,
    FOODORDER_DELIVERED_REQUEST,
    FOODORDER_DELIVERED_RESET,
    FOODORDER_DELIVERED_SUCCESS,
    FOODORDER_DETAILS_FAIL,
    FOODORDER_DETAILS_REQUEST,
    FOODORDER_DETAILS_SUCCESS,
    FOODORDER_LIST_FAIL,
    FOODORDER_LIST_REQUEST,
    FOODORDER_LIST_SUCCESS,
  } from "../Constants/FoodOrderConstants";
  
  export const foodorderListReducer = (state = { foodorders: [] }, action) => {
    switch (action.type) {
      case FOODORDER_LIST_REQUEST:
        return { loading: true };
      case FOODORDER_LIST_SUCCESS:
        return { loading: false, foodorders: action.payload };
      case FOODORDER_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // FOOD ORDER DETAILS
  export const foodorderDetailsReducer = (
    state = { loading: true, foodorderItems: [], shippingAddress: {} },
    action
  ) => {
    switch (action.type) {
      case FOODORDER_DETAILS_REQUEST:
        return { ...state, loading: true };
      case FOODORDER_DETAILS_SUCCESS:
        return { loading: false, fooforder: action.payload };
      case FOODORDER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // FOOD ORDER DELIVERED
  export const foodorderDeliveredReducer = (state = {}, action) => {
    switch (action.type) {
      case FOODORDER_DELIVERED_REQUEST:
        return { loading: true };
      case FOODORDER_DELIVERED_SUCCESS:
        return { loading: false, success: true };
      case FOODORDER_DELIVERED_FAIL:
        return { loading: false, error: action.payload };
      case FOODORDER_DELIVERED_RESET:
        return {};
      default:
        return state;
    }
  };
  