export const BDPROD_LIST_REQUEST = "BDPROD_LIST_REQUEST";
export const BDPROD_LIST_SUCCESS = "BDPROD_LIST_SUCCESS";
export const BDPROD_LIST_FAIL = "BDPROD_LIST_FAIL";

export const BDPROD_DELETE_REQUEST = "BDPROD_DELETE_REQUEST";
export const BDPROD_DELETE_SUCCESS = "BDPROD_DELETE_SUCCESS";
export const BDPROD_DELETE_FAIL = "BDPROD_DELETE_FAIL";

export const BDPROD_CREATE_REQUEST = "BDPROD_CREATE_REQUEST";
export const BDPROD_CREATE_SUCCESS = "BDPROD_CREATE_SUCCESS";
export const BDPROD_CREATE_FAIL = "BDPROD_CREATE_FAIL";
export const BDPROD_CREATE_RESET = "BDPROD_CREATE_RESET";





