// Import necessary dependencies and constants
import axios from "axios";
import {
  THPROD_CREATE_FAIL,
  THPROD_CREATE_REQUEST,
  THPROD_CREATE_SUCCESS,
  THPROD_DELETE_FAIL,
  THPROD_DELETE_REQUEST,
  THPROD_DELETE_SUCCESS,
  THPROD_LIST_FAIL,
  THPROD_LIST_REQUEST,
  THPROD_LIST_SUCCESS,
} from "../../Constants/WebConstants/ThreeProdConstant";
import { logout } from "../userActions";

// List Three Products action
export const listTHproduct = () => async (dispatch) => {
  try {
    dispatch({ type: THPROD_LIST_REQUEST });

    

    const { data } = await axios.get(`https://frontside.veetoore.store/api/threeprod/all`);

    dispatch({ type: THPROD_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: THPROD_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE Three Product
export const deleteTHproduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: THPROD_DELETE_REQUEST });

   

    await axios.delete(`https://frontside.veetoore.store/api/threeprod/${id}`);

    dispatch({ type: THPROD_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: THPROD_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE Three Product
export const createTHproduct = (thname, thdescription, filename, price) => async (dispatch) => {
  try {
    dispatch({ type: THPROD_CREATE_REQUEST });

    

    const requestData = {
      thname,
      thdescription,
      price,
      filename,
    };

    const response = await axios.post(`https://frontside.veetoore.store/api/threeprod/`, requestData, );

    dispatch({ type: THPROD_CREATE_SUCCESS, payload: response.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: THPROD_CREATE_FAIL,
      payload: message,
    });
  }
};
