import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../App.css';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../LoadingError/Toast';

import { FRESTAU_CREATE_RESET } from '../../Redux/Constants/ResFoodConstants';
import { toast } from 'react-toastify';

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};


const AddRestFood = () => {

  const [resname, setResName] = useState("");
  
  const [foname, setFoName] = useState("");
  
  const [fodescription, setFoDescription] = useState("");

  const [foprice, setFoPrice] = useState("");

  const [file, setResFFile] = useState("");

  const navigate = useNavigate();
 
 



  const setRFimgfile = (e) => {
    setResFFile(e.target.files[0])  
  }

 


  const dispatch = useDispatch();

  const restaufoodCreate = useSelector((state) => state.restaufoodCreate);
  const { loading, error, restausfood } = restaufoodCreate;


  useEffect(() => {
    if (restausfood) {
      toast.success('restau food Added', ToastObjects);
      dispatch({ type: FRESTAU_CREATE_RESET });
      // Clear all form fields
      setResName('');
      setFoName('');
      setFoDescription('');
      setFoPrice('');
      setResFFile(null); // Clear the file input
    }
  }, [restausfood, dispatch]);




  // adduser data

  const addFoodData = async (e) => {
    e.preventDefault();
    

    var formData = new FormData();
    formData.append("photo", file);
    formData.append("resname", resname);
    formData.append("foname", foname);
    formData.append("fodescription", fodescription);
    formData.append("foprice", foprice);

    
    

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    try {
    const res = await axios.post("https://frontside.veetoore.store/AddRestFood", formData, config);
  
      if (res.data.status === 401 || !res.data) {
        console.log("error");
      } else {
        toast.success('restau food Added', ToastObjects);
      dispatch({ type: FRESTAU_CREATE_RESET });
      // Clear all form fields
      setResName('');
      setFoName('');
      setFoDescription('');
      setFoPrice('');
      setResFFile(null); // Clear the file input 
  
        // Check if navigate is available before using it
        if (navigate) {
          navigate('/AddRestFood');
        }
      } 
    } catch (error) {
      console.error(error);
    }
  }



  return (
    <>
      <Toast />
      <div className="container mt-3" >
        <Form className="mt-3" onSubmit={addFoodData} >
          <Link to="/restausfood" className="btn btn-danger text-white right">
            Go to restaus foods
          </Link>
          <h2 className="content-title" id="fb">
            Add Restaurants food
          </h2>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm" id="cont">
                <div className="card-bodyf">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                  <Form.Group className="mb-3" controlId="formBasicNAME">
                    <Form.Label className="test">RESTAURANT NAME</Form.Label>
                    <Form.Control
                      type="text"
                      name="fname"
                      placeholder="Example (1962512)"
                      className="nobb"
                      value={resname}
                      onChange={(e) => setResName(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicFNAME">
                    <Form.Label className="test">RESTAURANT FOOD NAME</Form.Label>
                    <Form.Control
                      type="text"
                      name="catego"
                      placeholder="Example (par défaut)"
                      className="nobb"
                      value={foname}
                      onChange={(e) => setFoName(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicDISCRIPTION">
                    <Form.Label className="test">RESTAURANT FOOD DISCRIPTION</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="catego"
                      placeholder="Example (4017100127625)"
                      className="nobb"
                      value={fodescription}
                      onChange={(e) => setFoDescription(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPRICE">
                    <Form.Label className="test">RESTAURANT FOOD PRICE</Form.Label>
                    <Form.Control
                      type="text"
                      rows={3}
                      name="catego"
                      placeholder="Example (5,40)"
                      className="nobb"
                      value={foprice}
                      onChange={(e) => setFoPrice(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicIMG">
                    <Form.Label className="test">SELECT FOOD IMG</Form.Label>
                    <Form.Control
                      type="file"
                      name="photo"
                      placeholder="Example (PNG ou JPEG)"
                      className="nobb"
                      onChange={setRFimgfile}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}

export default AddRestFood;