import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteVTProduct } from "../../Redux/Actions/VTProductAction";

const VTProduct = (props) => {
  const { vtproduct } = props;
  const dispatch = useDispatch();

  const deletehandler = (id) => {
    if (window.confirm("Are you sure??")) {
      dispatch(deleteVTProduct(id));
    }
  };

  return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5" >
        <div className="card card-product-grid shadow-sm" id="bd">
          <Link to="#" className="img-wrap">
            <img src={`https://frontside.veetoore.store/upveet/${vtproduct.imagepath}`} alt="Product" />
          </Link>
          <div className="info-wrap">
            <Link to="#" className="title text-truncate">
              {vtproduct.name}
            </Link>
            <div className="price mb-2">{vtproduct.price} MAD</div>
            <div className="row">
              <Link
                to={`/vtproduct/${vtproduct._id}/edit`}
                className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <Link
                to="#"
                onClick={() => deletehandler(vtproduct._id)}
                className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VTProduct;
