export const RESTAU_LIST_REQUEST = "RESTAU_LIST_REQUEST";
export const RESTAU_LIST_SUCCESS = "RESTAU_LIST_SUCCESS";
export const RESTAU_LIST_FAIL = "RESTAU_LIST_FAIL";

export const RESTAU_EDIT_REQUEST = "RESTAU_EDIT_REQUEST";
export const RESTAU_EDIT_SUCCESS = "RESTAU_EDIT_SUCCESS";
export const RESTAU_EDIT_FAIL = "RESTAU_EDIT_FAIL";

export const RESTAU_DELETE_REQUEST = "RESTAU_DELETE_REQUEST";
export const RESTAU_DELETE_SUCCESS = "RESTAU_DELETE_SUCCESS";
export const RESTAU_DELETE_FAIL = "RESTAU_DELETE_FAIL";

export const RESTAU_CREATE_REQUEST = "RESTAU_CREATE_REQUEST";
export const RESTAU_CREATE_SUCCESS = "RESTAU_CREATE_SUCCESS";
export const RESTAU_CREATE_FAIL = "RESTAU_CREATE_FAIL";
export const RESTAU_CREATE_RESET = "RESTAU_CREATE_RESET";

export const RESTAU_UPDATE_REQUEST = "RESTAU_UPDATE_REQUEST";
export const RESTAU_UPDATE_SUCCESS = "RESTAU_UPDATE_SUCCESS";
export const RESTAU_UPDATE_FAIL = "RESTAU_UPDATE_FAIL";
export const RESTAU_UPDATE_RESET = "RESTAU_UPDATE_RESET";





