import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "../../../App.css";
import Message from "../../../components/LoadingError/Error";
import Loading from "../../../components/LoadingError/Loading";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../components/LoadingError/Toast";
import { WDPROD_CREATE_RESET } from "../../../Redux/Constants/WebConstants/WDproductConstant";
import { toast } from "react-toastify";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddWDProd = () => {

  const [wdname, setWDName] = useState("");
  const [wddescription, setWDdescription] = useState("");
  const [wdmiddisc, setWDmiddisc] = useState("");
  const [price, setPrice] = useState("");
  const [file, setFile] = useState("");

  const navigate = useNavigate();





  const setimgfile = (e) => {
      setFile(e.target.files[0])
  }




  const dispatch = useDispatch();

  const wdproductCreate = useSelector((state) => state.wdproductCreate);
  const { loading, error, wdproduct } = wdproductCreate;


  useEffect(() => {
      if (wdproduct) {
        toast.success('service Added', ToastObjects);
          dispatch({ type: WDPROD_CREATE_RESET });
          // Clear all form fields
          setWDName('');
          setWDdescription('');
          setWDmiddisc('');
          setPrice('');
          setFile(null); // Clear the file input
      }
  }, [wdproduct, dispatch]);




  // adduser data

  const addUserData = async (e) => {
      e.preventDefault();

      var formData = new FormData();
      formData.append("photo", file);
      formData.append("wdname", wdname);
      formData.append("wddescription", wddescription);
      formData.append("wdmiddisc", wdmiddisc);
      formData.append("price", price);





      const config = {
          headers: {
              "Content-Type": "multipart/form-data"
          }
      }

      
      try {
        const res = await axios.post("https://frontside.veetoore.store/AddWDProd", formData, config);
        
          if (res.data.status === 401 || !res.data) {
            console.log("error");
          } else {
            toast.success('NEW SERVICE Added', ToastObjects);
            dispatch({ type: WDPROD_CREATE_RESET });
          // Clear all form fields
          setWDName('');
          setWDdescription('');
          setWDmiddisc('');
          setPrice('');
          setFile(null); // Clear the file input 
      
            // Check if navigate is available before using it
            if (navigate) {
              navigate('/wdproduct');
            }
          } 
        } catch (error) {
          console.error(error);
        }
  }

  return (
    <>
      <Toast />

      <div className="container mt-3">
        <form onSubmit={addUserData}>
          <Link to="/bdproduct" className="btn btn-danger text-white right">
            Go to All Web Deals
          </Link>
          <h2 className="content-title" id="fb">
            Add Welcome Deals Products
          </h2>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm" id="cont">
                <div className="card-bodyf">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="test">Welcome Deals Product name</Form.Label>
                      <Form.Control
                        type="text"
                        name="pricee"
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={wdname}
                        onChange={(e) => setWDName(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="test">Welcome Deals Product Discription</Form.Label>
                      <Form.Control
                        type="text"
                        name="pricee"
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={wddescription}
                        onChange={(e) => setWDdescription(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="test">Welcome Deals Price</Form.Label>
                      <Form.Control
                        type="text"
                        name="pricee"
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="test">Welcome Deals Product Midle Disc</Form.Label>
                      <Form.Control
                        type="text"
                        name="pricee"
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={wdmiddisc}
                        onChange={(e) => setWDmiddisc(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className="test">
                        Select Welcome Deals Product Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="photo"
                        placeholder="Exaple( PNG ou JPEG )"
                        className="nobb"
                        onChange={setimgfile}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={addUserData}
                    >
                      Submit
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddWDProd;
