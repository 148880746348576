import React from "react";
import Sidebar from "./../../components/sidebar";
import Header from "./../../components/Header";
import BDProductmain from "../../components/AWebsite/BeastDeals/BDProductmain";
import ServicesMain from "../../components/AWebsite/services/ServicesMain";
import PLBDProductmain from "../../components/AWebsite/PlusBDproduct/PLBDproductse";
import ThreeProdmain from "../../components/AWebsite/ThreeProduct/ThreeProduct";
import WelcProdmain from "../../components/AWebsite/WelcomDeals/WDproduct";

const BDProductScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <BDProductmain />
        <ServicesMain />
        <PLBDProductmain />
        <ThreeProdmain />
        <WelcProdmain />
      </main>
    </>
  );
};

export default BDProductScreen;
