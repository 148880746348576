import React from "react";
import Sidebar from "./../../components/sidebar";
import Header from "./../../components/Header";
import AddBDproduct from "../../components/AWebsite/BeastDeals/AddBDproduct";



const AddBDProductScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddBDproduct />
      </main>
    </>
  );
};

export default AddBDProductScreen;
