import {
    BDPROD_CREATE_FAIL,
    BDPROD_CREATE_REQUEST,
    BDPROD_CREATE_RESET,
    BDPROD_CREATE_SUCCESS,
    BDPROD_DELETE_FAIL,
    BDPROD_DELETE_REQUEST,
    BDPROD_DELETE_SUCCESS,
    BDPROD_LIST_FAIL,
    BDPROD_LIST_REQUEST,
    BDPROD_LIST_SUCCESS,
  } from "../../Constants/WebConstants/BDproductConstant";
  
  // ALL RESTAUS
  export const bdproductListReducer = (state = { bdproducts: [] }, action) => {
    switch (action.type) {
      case BDPROD_LIST_REQUEST:
        return { loading: true, bdproducts: [] };
      case BDPROD_LIST_SUCCESS:
        return { loading: false, bdproducts: action.payload };
      case BDPROD_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE RESTAU
  export const bdproductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case BDPROD_DELETE_REQUEST:
        return { loading: true };
      case BDPROD_DELETE_SUCCESS:
        return { loading: false, success: true };
      case BDPROD_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // CREATe RESTAU
  export const bdproductCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case BDPROD_CREATE_REQUEST:
        return { loading: true };
      case BDPROD_CREATE_SUCCESS:
        return { loading: false, success: true, bdproducts: action.payload };
      case BDPROD_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case BDPROD_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT RESTAU





