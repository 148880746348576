// Import necessary dependencies and constants
import axios from "axios";
import {
  SERVIC_CREATE_FAIL,
  SERVIC_CREATE_REQUEST,
  SERVIC_CREATE_SUCCESS,
  SERVIC_DELETE_FAIL,
  SERVIC_DELETE_REQUEST,
  SERVIC_DELETE_SUCCESS,
  SERVIC_LIST_FAIL,
  SERVIC_LIST_REQUEST,
  SERVIC_LIST_SUCCESS,
} from "../../Constants/WebConstants/ServiceCanstat";
import { logout } from "../userActions";

// List Services action
export const listService = () => async (dispatch) => {
  try {
    dispatch({ type: SERVIC_LIST_REQUEST });

    

    const { data } = await axios.get(`https://frontside.veetoore.store/api/services/all`);

    dispatch({ type: SERVIC_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SERVIC_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE Service
export const deleteService = (id) => async (dispatch) => {
  try {
    dispatch({ type: SERVIC_DELETE_REQUEST });

    

    await axios.delete(`https://frontside.veetoore.store/api/services/${id}`);

    dispatch({ type: SERVIC_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SERVIC_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE Service
export const createService = (sname, filename) => async (dispatch) => {
  try {
    dispatch({ type: SERVIC_CREATE_REQUEST });

    

    const { data } = await axios.post(`https://frontside.veetoore.store/api/services/`, { sname, filename });

    dispatch({ type: SERVIC_CREATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SERVIC_CREATE_FAIL,
      payload: message,
    });
  }
};
