export const FOODORDER_LIST_REQUEST = "FOODORDER_LIST_REQUEST";
export const FOODORDER_LIST_SUCCESS = "FOODORDER_LIST_SUCCESS";
export const FOODORDER_LIST_FAIL = "FOODORDER_LIST_FAIL";

export const FOODORDER_DETAILS_REQUEST = "FOODORDER_DETAILS_REQUEST";
export const FOODORDER_DETAILS_SUCCESS = "FOODORDER_DETAILS_SUCCESS";
export const FOODORDER_DETAILS_FAIL = "FOODORDER_DETAILS_FAIL";

export const FOODORDER_DELIVERED_REQUEST = "FOODORDER_DELIVERED_REQUEST";
export const FOODORDER_DELIVERED_SUCCESS = "FOODORDER_DELIVERED_SUCCESS";
export const FOODORDER_DELIVERED_FAIL = "FOODORDER_DELIVERED_FAIL";
export const FOODORDER_DELIVERED_RESET = "FOODORDER_DELIVERED_RESET";
