import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteRetau } from "../../Redux/Actions/RestoAction";

const Restau = (props) => {
  const { restau } = props;
  const dispatch = useDispatch();

  const deletehandler = (id) => {
    if (window.confirm("Are you sure??")) {
      dispatch(deleteRetau(id));
    }
  };

  return (
    <>
    
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5" >
        <div className="card card-product-grid shadow-sm" id="bd">
          <Link to="#" className="img-wrap">
   <img src={`https://frontside.veetoore.store/uresto/${restau.imagpath}`} alt="restau" />

          </Link>
          <div className="info-wrap">
            <Link to="#" className="title text-truncate">
              {restau.resname}
            </Link>
            <div className="price mb-2">{restau.rescateg}</div>
            <div className="row">
              <Link
                to={`/restau/${restau._id}/edit`}
                className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <Link
                to="#"
                onClick={() => deletehandler(restau._id)}
                className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default Restau;
