// Import necessary dependencies and constants
import axios from "axios";
import {
  PLBDPROD_CREATE_FAIL,
  PLBDPROD_CREATE_REQUEST,
  PLBDPROD_CREATE_SUCCESS,
  PLBDPROD_DELETE_FAIL,
  PLBDPROD_DELETE_REQUEST,
  PLBDPROD_DELETE_SUCCESS,
  PLBDPROD_LIST_FAIL,
  PLBDPROD_LIST_REQUEST,
  PLBDPROD_LIST_SUCCESS,
} from "../../Constants/WebConstants/PLBDprodConstant";
import { logout } from "../userActions";

// List PLBDProducts action
export const listPLBDproduct = () => async (dispatch) => {
  try {
    dispatch({ type: PLBDPROD_LIST_REQUEST });

   

    const { data } = await axios.get(`https://frontside.veetoore.store/api/plusbestd/all`);

    dispatch({ type: PLBDPROD_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PLBDPROD_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE PLBDProduct
export const deletePLBDproduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLBDPROD_DELETE_REQUEST });

   

    await axios.delete(`https://frontside.veetoore.store/api/plusbestd/${id}`);

    dispatch({ type: PLBDPROD_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PLBDPROD_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE PLBDProduct
export const createPLBDproduct = (plname, pldescription, filename, price) => async (dispatch) => {
  try {
    dispatch({ type: PLBDPROD_CREATE_REQUEST });

    

    const requestData = {
      plname,
      pldescription,
      price,
      filename,
    };

    const response = await axios.post(`https://frontside.veetoore.store/api/plusbestd/`, requestData);

    dispatch({ type: PLBDPROD_CREATE_SUCCESS, payload: response.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PLBDPROD_CREATE_FAIL,
      payload: message,
    });
  }
};
