import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "../../../App.css";
import Message from "../../../components/LoadingError/Error";
import Loading from "../../../components/LoadingError/Loading";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../components/LoadingError/Toast";
import { PLBDPROD_CREATE_RESET } from "../../../Redux/Constants/WebConstants/PLBDprodConstant";
import { toast } from "react-toastify";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddPLBDproduct = () => {

  
  const [plname, setPLName] = useState("");
  
  const [pldescription, setPLDescription] = useState("");
  
  const [price, setPrice] = useState("");

  const [file, setResFFile] = useState("");

  const navigate = useNavigate();
 
 



  const setRFimgfile = (e) => {
    setResFFile(e.target.files[0])  
  }

  


  const dispatch = useDispatch();

  const plbdproductCreate = useSelector((state) => state.plbdproductCreate);
  const { loading, error, plbdproduct } = plbdproductCreate;


  useEffect(() => {
    if (plbdproduct) {
      toast.success('restau food Added', ToastObjects);
      dispatch({ type: PLBDPROD_CREATE_RESET });
      // Clear all form fields
      setPLName('');
      setPLDescription('');
      setPrice('');
      setResFFile(null); // Clear the file input
    }
  }, [plbdproduct, dispatch]);




  // adduser data

  const addFoodData = async (e) => {
    e.preventDefault();
    

    var formData = new FormData();
    formData.append("photo", file);
    formData.append("plname", plname);
    formData.append("pldescription", pldescription);
    formData.append("price", price);

    
    

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    
    
    try {
      const res = await axios.post("https://frontside.veetoore.store/AddPLBDproduct", formData, config);
    
        if (res.data.status === 401 || !res.data) {
          console.log("error");
        } else {
          toast.success('PL Best Deals Product Added', ToastObjects);
        dispatch({ type: PLBDPROD_CREATE_RESET });
        // Clear all form fields
        setPLName('');
        setPLDescription('');
        setPrice('');
        setResFFile(null); // Clear the file input 
    
          // Check if navigate is available before using it
          if (navigate) {
            navigate('/addplbdprod');
          }
        } 
      } catch (error) {
        console.error(error);
      }
  } 

  return (
    <>
      <Toast />

      <div className="container mt-3">
        <form onSubmit={addFoodData}>
          <Link to="/bdproduct" className="btn btn-danger text-white right">
            Go to All Web Deals
          </Link>
          <h2 className="content-title" id="fb">
            Add Plus Best Product
          </h2>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm" id="cont">
                <div className="card-bodyf">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                  <Form.Group className="mb-3" controlId="formBasicPBDname">
                    <Form.Label className="test">
                      Plus Best Deals NAME
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="catego"
                      placeholder="Exaple( par défaut )"
                      className="nobb"
                      value={plname}
                      onChange={(e) => setPLName(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPLDcateg">
                    <Form.Label className="test">
                    Plus Best Deals DISCRIPTION
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="catego"
                      placeholder="Exaple( 4017100127625 )"
                      className="nobb"
                      value={pldescription}
                      onChange={(e) => setPLDescription(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPrice">
                    <Form.Label className="test">
                    Plus Best Deals PRICE
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="catego"
                      placeholder="Exaple( 4017100127625 )"
                      className="nobb"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicImg">
                    <Form.Label className="test">SELECT PBestDeals IMG</Form.Label>
                    <Form.Control
                      type="file"
                      name="photo"
                      placeholder="Exaple( PNG ou JPEG )"
                      className="nobb"
                      onChange={setRFimgfile}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" onClick={addFoodData}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPLBDproduct;
