import {
    WDPROD_CREATE_FAIL,
    WDPROD_CREATE_REQUEST,
    WDPROD_CREATE_RESET,
    WDPROD_CREATE_SUCCESS,
    WDPROD_DELETE_FAIL,
    WDPROD_DELETE_REQUEST,
    WDPROD_DELETE_SUCCESS,
    WDPROD_LIST_FAIL,
    WDPROD_LIST_REQUEST,
    WDPROD_LIST_SUCCESS,
  } from "../../Constants/WebConstants/WDproductConstant";
  
  // ALL RESTAUS
  export const wdproductListReducer = (state = { wdproducts: [] }, action) => {
    switch (action.type) {
      case   WDPROD_LIST_REQUEST:
        return { loading: true, wdproducts: [] };
      case  WDPROD_LIST_SUCCESS:
        return { loading: false, wdproducts: action.payload };
      case   WDPROD_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE RESTAU
  export const wdproductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case WDPROD_DELETE_REQUEST:
        return { loading: true };
      case WDPROD_DELETE_SUCCESS:
        return { loading: false, success: true };
      case WDPROD_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // CREATe RESTAU
  export const wdproductCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case WDPROD_CREATE_REQUEST:
        return { loading: true };
      case WDPROD_CREATE_SUCCESS:
        return { loading: false, success: true, wdproducts: action.payload };
      case WDPROD_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case WDPROD_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT RESTAU





