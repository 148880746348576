export const VTPRODUCT_LIST_REQUEST = "VTPRODUCT_LIST_REQUEST";
export const VTPRODUCT_LIST_SUCCESS = "VTPRODUCT_LIST_SUCCESS";
export const VTPRODUCT_LIST_FAIL = "VTPRODUCT_LIST_FAIL";

export const VTPRODUCT_EDIT_REQUEST = "VTPRODUCT_EDIT_REQUEST";
export const VTPRODUCT_EDIT_SUCCESS = "VTPRODUCT_EDIT_SUCCESS";
export const VTPRODUCT_EDIT_FAIL = "VTPRODUCT_EDIT_FAIL";

export const VTPRODUCT_DELETE_REQUEST = "VTPRODUCT_DELETE_REQUEST";
export const VTPRODUCT_DELETE_SUCCESS = "VTPRODUCT_DELETE_SUCCESS";
export const VTPRODUCT_DELETE_FAIL = "VTPRODUCT_DELETE_FAIL";

export const VTPRODUCT_CREATE_REQUEST = "VTPRODUCT_CREATE_REQUEST";
export const VTPRODUCT_CREATE_SUCCESS = "VTPRODUCT_CREATE_SUCCESS";
export const VTPRODUCT_CREATE_FAIL = "VTPRODUCT_CREATE_FAIL";
export const VTPRODUCT_CREATE_RESET = "VTPRODUCT_CREATE_RESET";

export const VTPRODUCT_UPDATE_REQUEST = "VTPRODUCT_UPDATE_REQUEST";
export const VTPRODUCT_UPDATE_SUCCESS = "VTPRODUCT_UPDATE_SUCCESS";
export const VTPRODUCT_UPDATE_FAIL = "VTPRODUCT_UPDATE_FAIL";
export const VTPRODUCT_UPDATE_RESET = "VTPRODUCT_UPDATE_RESET";
