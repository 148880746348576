import React from "react";

const OrderStatistics = () => {
  return (
    <div className="" id="hm">
      <div className="card mb-4 " id="hm">
        <article className="card-body">
          <h5 className="card-title" id="kl">Order statistics</h5>
          <iframe
            style={{
              background: "#465884",
              border: "none",
              borderRadius: "2px",
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
              width: "100%",
              height: "350px",
            }}
            title="Order stat"
            src="https://charts.mongodb.com/charts-veedash-uuyok/embed/charts?id=6531da97-1331-49a8-8606-5d83441ca923&maxDataAge=300&theme=dark&autoRefresh=true
            "
          ></iframe>
        </article>
      </div>
    </div>
  );
};

export default OrderStatistics;










