import {
    PLBDPROD_CREATE_FAIL,
    PLBDPROD_CREATE_REQUEST,
    PLBDPROD_CREATE_RESET,
    PLBDPROD_CREATE_SUCCESS,
    PLBDPROD_DELETE_FAIL,
    PLBDPROD_DELETE_REQUEST,
    PLBDPROD_DELETE_SUCCESS,
    PLBDPROD_LIST_FAIL,
    PLBDPROD_LIST_REQUEST,
    PLBDPROD_LIST_SUCCESS,
  } from "../../Constants/WebConstants/PLBDprodConstant";
  
  // ALL RESTAUS
  export const plbdproductListReducer = (state = { plbdproducts: [] }, action) => {
    switch (action.type) {
      case PLBDPROD_LIST_REQUEST:
        return { loading: true, plbdproducts: [] };
      case PLBDPROD_LIST_SUCCESS:
        return { loading: false, plbdproducts: action.payload };
      case PLBDPROD_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE RESTAU
  export const plbdproductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case PLBDPROD_DELETE_REQUEST:
        return { loading: true };
      case PLBDPROD_DELETE_SUCCESS:
        return { loading: false, success: true };
      case PLBDPROD_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // CREATe RESTAU
  export const plbdproductCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case PLBDPROD_CREATE_REQUEST:
        return { loading: true };
      case PLBDPROD_CREATE_SUCCESS:
        return { loading: false, success: true, plbdproducts: action.payload };
      case PLBDPROD_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case PLBDPROD_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT RESTAU





