import {
    VTPRODUCT_CREATE_FAIL,
    VTPRODUCT_CREATE_REQUEST,
    VTPRODUCT_CREATE_RESET,
    VTPRODUCT_CREATE_SUCCESS,
    VTPRODUCT_DELETE_FAIL,
    VTPRODUCT_DELETE_REQUEST,
    VTPRODUCT_DELETE_SUCCESS,
    VTPRODUCT_EDIT_FAIL,
    VTPRODUCT_EDIT_REQUEST,
    VTPRODUCT_EDIT_SUCCESS,
    VTPRODUCT_LIST_FAIL,
    VTPRODUCT_LIST_REQUEST,
    VTPRODUCT_LIST_SUCCESS,
    VTPRODUCT_UPDATE_FAIL,
    VTPRODUCT_UPDATE_REQUEST,
    VTPRODUCT_UPDATE_RESET,
    VTPRODUCT_UPDATE_SUCCESS,
  } from "../Constants/VTProductConstants";
  
  // ALL VTPRODUCTS
  export const vtproductListReducer = (state = { vtproducts: [] }, action) => {
    switch (action.type) {
      case VTPRODUCT_LIST_REQUEST:
        return { loading: true, vtproducts: [] };
      case VTPRODUCT_LIST_SUCCESS:
        return { loading: false, vtproducts: action.payload };
      case VTPRODUCT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE VTPRODUCT
  export const vtproductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case VTPRODUCT_DELETE_REQUEST:
        return { loading: true };
      case VTPRODUCT_DELETE_SUCCESS:
        return { loading: false, success: true };
      case VTPRODUCT_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE VTPRODUCT
  export const vtproductCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case VTPRODUCT_CREATE_REQUEST:
        return { loading: true };
      case VTPRODUCT_CREATE_SUCCESS:
        return { loading: false, success: true, vtproducts: action.payload };
      case VTPRODUCT_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case VTPRODUCT_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT VTPRODUCT
  export const vtproductEditReducer = (
    state = { vtproduct: { reviews: [] } },
    action
  ) => {
    switch (action.type) {
      case VTPRODUCT_EDIT_REQUEST:
        return { ...state, loading: true };
      case VTPRODUCT_EDIT_SUCCESS:
        return { loading: false, vtproduct: action.payload };
      case VTPRODUCT_EDIT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // UPDATE VTPRODUCT
  export const vtproductUpdateReducer = (state = { vtproduct: {} }, action) => {
    switch (action.type) {
      case VTPRODUCT_UPDATE_REQUEST:
        return { loading: true };
      case VTPRODUCT_UPDATE_SUCCESS:
        return { loading: false, success: true, vtproduct: action.payload };
      case VTPRODUCT_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case VTPRODUCT_UPDATE_RESET:
        return { VTPRODUCT: {} };
      default:
        return state;
    }
  };
  