import {
    THPROD_CREATE_FAIL,
    THPROD_CREATE_REQUEST,
    THPROD_CREATE_RESET,
    THPROD_CREATE_SUCCESS,
    THPROD_DELETE_FAIL,
    THPROD_DELETE_REQUEST,
    THPROD_DELETE_SUCCESS,
    THPROD_LIST_FAIL,
    THPROD_LIST_REQUEST,
    THPROD_LIST_SUCCESS,
  } from "../../Constants/WebConstants/ThreeProdConstant";
  
  // ALL RESTAUS
  export const thproductListReducer = (state = { thproducts: [] }, action) => {
    switch (action.type) {
      case   THPROD_LIST_REQUEST:
        return { loading: true, thproducts: [] };
      case  THPROD_LIST_SUCCESS:
        return { loading: false, thproducts: action.payload };
      case   THPROD_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // DELETE RESTAU
  export const thproductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case THPROD_DELETE_REQUEST:
        return { loading: true };
      case THPROD_DELETE_SUCCESS:
        return { loading: false, success: true };
      case THPROD_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // CREATe RESTAU
  export const thproductCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case THPROD_CREATE_REQUEST:
        return { loading: true };
      case THPROD_CREATE_SUCCESS:
        return { loading: false, success: true, thproducts: action.payload };
      case THPROD_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case THPROD_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // EDIT RESTAU





