import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';
import Message from '../../components/LoadingError/Error';
import Loading from '../../components/LoadingError/Loading';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../components/LoadingError/Toast';
import { toast } from 'react-toastify';
import { VTPRODUCT_CREATE_RESET } from '../../Redux/Constants/VTProductConstants';

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const VTAProduct = () => {

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");

  const navigate = useNavigate();





  const setimgfile = (e) => {
      setFile(e.target.files[0])
  }




  const dispatch = useDispatch();

  const vtproductCreate = useSelector((state) => state.vtproductCreate);
  const { loading, error, vtproduct } = vtproductCreate;


  useEffect(() => {
      if (vtproduct) {
        toast.success('Product Added', ToastObjects);
          dispatch({ type: VTPRODUCT_CREATE_RESET });
          // Clear all form fields
          setName('');
          setPrice('');
          setCountInStock('');
          setDescription('');
          setFile(null); // Clear the file input
      }
  }, [vtproduct, dispatch]);




  // adduser data

  const addUserData = async (e) => {
      e.preventDefault();

      var formData = new FormData();
      formData.append("photo", file);
      formData.append("name", name);
      formData.append("countInStock", countInStock);
      formData.append("description", description);
      formData.append("price", price);





      const config = {
          headers: {
              "Content-Type": "multipart/form-data"
          }
      }

      try {
        const res = await axios.post("https://frontside.veetoore.store/VTAProduct", formData, config);
      
          if (res.data.status === 401 || !res.data) {
            console.log("error");
          } else {
            toast.success('Product Added', ToastObjects);
          dispatch({ type: VTPRODUCT_CREATE_RESET });
          // Clear all form fields
          setName('');
          setPrice('');
          setCountInStock('');
          setDescription('');
          setFile(null); // Clear the file input 
      
            // Check if navigate is available before using it
            if (navigate) {
              navigate('/VTAddProduct');
            }
          } 
        } catch (error) {
          console.error(error);
        }
     
  }




  return (
    <>
      <Toast />

      <div className="container mt-3"  >
        <form onSubmit={addUserData}>
          <Link to="/vtproducts" className="btn btn-danger text-white right">
            Go to VT products
          </Link>
          <h2 className="content-title" id='fb'>Add Veetoor Products</h2>


          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8" >
              <div className="card mb-4 shadow-sm" id='cont'>
                <div className="card-bodyf" >
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}






                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className="test">Product name</Form.Label>
                      <Form.Control
                        type="text"
                        name='pricee'
                        placeholder="Exaple( Minis biscuits au beurre original Zoo 30g - BAHLSEN )"
                        className="nobb"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="off"

                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicDisc">
                      <Form.Label className="test">Product description</Form.Label>
                      <Form.Control
                        type="text"
                        name='disce'
                        placeholder="Exaple( BAHLSEN )"
                        className="nobb"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        autoComplete="off"

                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicStock">
                      <Form.Label className="test">Product countInStock</Form.Label>
                      <Form.Control
                        type="text"
                        name='catego'
                        placeholder="Exaple( 5,40 )"
                        className="nobb"
                        value={countInStock}
                        onChange={(e) => setCountInStock(e.target.value)}
                        autoComplete="off"

                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPrice">
                      <Form.Label className="test">Product price</Form.Label>
                      <Form.Control
                        type="text"
                        name='catego'
                        placeholder="Exaple( par défaut )"
                        className="nobb"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        autoComplete="off"


                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicImg">
                      <Form.Label className="test">Select Product Image</Form.Label>
                      <Form.Control
                        type="file"
                        name='photo'
                        placeholder="Exaple( PNG ou JPEG )"
                        className="nobb"
                        onChange={setimgfile}
                        autoComplete="off"

                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={addUserData}>
                      Submit
                    </Button>



                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default VTAProduct;