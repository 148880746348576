import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/productScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import AddProduct from "./screens/AddProductscreen";  
import Login from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import NotFound from "./screens/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "./Redux/Actions/ProductActions";
import { listOrders } from "./Redux/Actions/OrderActions";
import AddRestau from "./screens/AddRestauscreen";
import RestaScreen from "./screens/RestaScreen";
import AddRestauFood from "./screens/AddRestauFoodscreen";
import ResFoodScreen from "./screens/ResFoodScreen";
import VTAddProduct from "./screens/VTAddProduct";
import VTProductScreen from "./screens/VTProductScreen";
import AddSRVScreen from "./screens/WebScreens/AddSRVScreen";
import AddBDProductScreen from "./screens/WebScreens/AddBDproductScreen";
import BDProductScreen from "./screens/WebScreens/ALLDEALScreen";
import AddPLBDProductScreen from "./screens/WebScreens/AddPLBDprod";
import AddTHProductScreen from "./screens/WebScreens/AddThProduct";
import AddWDProductScreen from "./screens/WebScreens/AddWDproductScreen";
import PrivateRoute from "./PrivateRouter";
import { listVTProducts } from "./Redux/Actions/VTProductAction";
import { listRestaus } from "./Redux/Actions/RestoAction";

function App() { 
  const dispatch = useDispatch();


  // Include the LogoutTimer component




  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin; 

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listProducts());
      dispatch(listOrders());
      dispatch(listRestaus());
      dispatch(listVTProducts());
    }
  }, [dispatch, userInfo]);

  return (
    <>
      <Router>
        <Routes basename="/double">
        <Route
            path="/"
            element={
              <PrivateRoute/>
                
            }
          >
            <Route path="/" element={<HomeScreen />} />
            <Route path="addsrvice" element={<AddSRVScreen />} />
            <Route path="addbdproduct" element={<AddBDProductScreen />} />
            <Route path="addplbdprod" element={<AddPLBDProductScreen />} />
            <Route path="bdproduct" element={<BDProductScreen />} />
            <Route path="thproduct" element={<AddTHProductScreen />} />
            <Route path="wdproduct" element={<AddWDProductScreen />} />
            <Route path="vtproducts" element={<VTProductScreen />} />
            <Route path="restaus" element={<RestaScreen />} />
            <Route path="restausfood" element={<ResFoodScreen />} />
            <Route path="vtaddproduct" element={<VTAddProduct />} />
            <Route path="addrestau" element={<AddRestau />} />
            <Route path="addrestfood" element={<AddRestauFood />} />

            <Route path="products" element={<ProductScreen />} />
            <Route path="category" element={<CategoriesScreen />} />
            <Route path="orders" element={<OrderScreen />} />
            <Route path="order/:id" element={<OrderDetailScreen />} />
            <Route path="addproduct" element={<AddProduct />} />
            <Route path="users" element={<UsersScreen />} />
            <Route path="product/:id/edit" element={<ProductEditScreen />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
