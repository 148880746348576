export const SERVIC_LIST_REQUEST = "SERVIC_LIST_REQUEST";
export const SERVIC_LIST_SUCCESS = "SERVIC_LIST_SUCCESS";
export const SERVIC_LIST_FAIL = "SERVIC_LIST_FAIL";


export const SERVIC_DELETE_REQUEST = "SERVIC_DELETE_REQUEST";
export const SERVIC_DELETE_SUCCESS = "SERVIC_DELETE_SUCCESS";
export const SERVIC_DELETE_FAIL = "SERVIC_DELETE_FAIL";

export const SERVIC_CREATE_REQUEST = "SERVIC_CREATE_REQUEST";
export const SERVIC_CREATE_SUCCESS = "SERVIC_CREATE_SUCCESS";
export const SERVIC_CREATE_FAIL = "SERVIC_CREATE_FAIL";
export const SERVIC_CREATE_RESET = "SERVIC_CREATE_RESET";

