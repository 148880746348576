import React from "react";
import TopTotal from "./TopTotal";
import LatestOrder from "./LatestOrder";
import SaleStatistics from "./SalesStatistics";
import ProductsStatistics from "./ProductsStatistics";
import { useSelector } from "react-redux";
import RestaurantsStatistics from "./RestausStatistics";
import OrderStatistics from "./OrderStatistic";


const Main = () => {
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;
  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  const restauList = useSelector((state) => state.restauList);
  const { restaus } = restauList;
   
  const vtproductList = useSelector((state) => state.vtproductList);
  const { vtproducts } = vtproductList;
 

 

  return (
    <>
      <section className="content-main" >
        <div className="content-header">
          <h2 className="content-title" id="sds" >VeeTooR CRUD DASHBOARD </h2>
        </div>
        {/* Top Total */} 
         <TopTotal orders={orders} products={products} restaus={restaus} vtproducts={vtproducts} />

        <div className="row">
          {/* STATICS */}
          <SaleStatistics />
          <ProductsStatistics />
          <OrderStatistics />
          <RestaurantsStatistics />
        </div>

        {/* LATEST ORDER */}
        <div className="card mb-4 ">
          <LatestOrder orders={orders} loading={loading} error={error} />
        </div>
      </section>
    </>
  );
};

export default Main;
 