import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../../App.css";
import Message from "../../LoadingError/Error";
import Loading from "../../LoadingError/Loading";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../LoadingError/Toast";

import { toast } from "react-toastify";
import { BDPROD_CREATE_RESET } from "../../../Redux/Constants/WebConstants/BDproductConstant";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddBDproduct = () => {

  
  const [bdname, setBDName] = useState("");
  
  const [bdescription, setBDescription] = useState("");


  const [file, setResFFile] = useState("");

  const navigate = useNavigate();
 
 



  const setRFimgfile = (e) => {
    setResFFile(e.target.files[0])  
  }

 


  const dispatch = useDispatch();

  const bdproductCreate = useSelector((state) => state.bdproductCreate);
  const { loading, error, bdproduct } = bdproductCreate;


  useEffect(() => {
    if (bdproduct) {
      toast.success('restau food Added', ToastObjects);
      dispatch({ type: BDPROD_CREATE_RESET });
      // Clear all form fields
      setBDName('');
      setBDescription('');
      setResFFile(null); // Clear the file input
    }
  }, [bdproduct, dispatch]);




  // adduser data

  const addFoodData = async (e) => {
    e.preventDefault();
    

    var formData = new FormData();
    formData.append("photo", file);
    formData.append("bdname", bdname);
    formData.append("bdescription", bdescription);

    
    

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    
    try {
      const res = await axios.post("https://frontside.veetoore.store/AddBDproduct", formData, config);
    
        if (res.data.status === 401 || !res.data) {
          console.log("error");
        } else {
          toast.success('Best Deals Product Added', ToastObjects);
        dispatch({ type: BDPROD_CREATE_RESET });
        // Clear all form fields
        setBDName('');
        setBDescription('');
        setResFFile(null); // Clear the file input 
    
          // Check if navigate is available before using it
          if (navigate) {
            navigate('/addbdproduct');
          }
        } 
      } catch (error) {
        console.error(error);
      }
   
  } 


  return (
    <>
      <Toast />

      <div className="container mt-3">
        <form onSubmit={addFoodData}>
          <Link to="/bdproduct" className="btn btn-danger text-white right">
            Go to All web BDP
          </Link>
          <h2 className="content-title" id="fb">
            Add Web Best Deals Product
          </h2>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm" id="cont">
                <div className="card-bodyf">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                  <Form.Group className="mb-3" controlId="formBasicBestD">
                    <Form.Label className="test">
                      BEST DEALS NAME 
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="catego"
                      placeholder="Exaple( par défaut )"
                      className="nobb"
                      value={bdname}
                      onChange={(e) => setBDName(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicBDname">
                    <Form.Label className="test">
                    BEST DEALS DISCRIPTION
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="catego"
                      placeholder="Exaple( 4017100127625 )"
                      className="nobb"
                      value={bdescription}
                      onChange={(e) => setBDescription(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicBDimg">
                    <Form.Label className="test">BEST DEALS IMG</Form.Label>
                    <Form.Control
                      type="file"
                      name="photo"
                      placeholder="Exaple( PNG ou JPEG )"
                      className="nobb"
                      onChange={setRFimgfile}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" onClick={addFoodData}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBDproduct;
