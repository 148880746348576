import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../Constants/UserContants";
import axios from "axios";
import { toast } from "react-toastify";








// LOGIN

export const login = (email, password) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const { data } = await axios.post(`https://frontside.veetoore.store/api/users/login`, { email, password }, config);

    if (data.isAdmin) {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } else {
      // Handle non-admin user
      toast.error("You are not Admin shit");
      dispatch({ type: USER_LOGIN_FAIL, payload: "You are not Admin" });
    }
  } catch (error) {
    console.error("Login error:", error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response?.data?.message || "Login failed",
    });
  }
};
// LOGOUT
// userActions.js


// userActions.js


export const logout = () => async (dispatch) => {
  try {
    // Perform any local storage or client-side cleanup
    localStorage.removeItem('userInfo');

    // Clear the 'connect.sid' cookie without specifying path or expires

    // Dispatch the logout actions
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_LIST_RESET });

    // Make an API call to the server to logout (optional)
    await fetch('https://frontside.veetoore.store/api/logout', {
      method: 'POST',
    });
  } catch (error) {
    console.error('Error during logout:', error);
  }
};



// ALL USER
export const listUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const { data } = await axios.get(`https://frontside.veetoore.store/api/users`, config);

    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};
