// Import necessary dependencies and constants
import axios from "axios";
import {
  VTPRODUCT_CREATE_FAIL,
  VTPRODUCT_CREATE_REQUEST,
  VTPRODUCT_CREATE_SUCCESS,
  VTPRODUCT_DELETE_FAIL,
  VTPRODUCT_DELETE_REQUEST,
  VTPRODUCT_DELETE_SUCCESS,
  VTPRODUCT_EDIT_FAIL,
  VTPRODUCT_EDIT_REQUEST,
  VTPRODUCT_EDIT_SUCCESS,
  VTPRODUCT_LIST_FAIL,
  VTPRODUCT_LIST_REQUEST,
  VTPRODUCT_LIST_SUCCESS,
  VTPRODUCT_UPDATE_FAIL,
  VTPRODUCT_UPDATE_REQUEST,
  VTPRODUCT_UPDATE_SUCCESS,
} from "../Constants/VTProductConstants";
import { logout } from "./userActions";


// List VTProducts action
export const listVTProducts = () => async (dispatch) => {
  try {
    dispatch({ type: VTPRODUCT_LIST_REQUEST });

   

    const { data } = await axios.get(`https://frontside.veetoore.store/api/vtproducts/all`);

    dispatch({ type: VTPRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: VTPRODUCT_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE VTProduct
export const deleteVTProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: VTPRODUCT_DELETE_REQUEST });

    

    await axios.delete(`https://frontside.veetoore.store/api/vtproducts/${id}`);

    dispatch({ type: VTPRODUCT_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: VTPRODUCT_DELETE_FAIL,
      payload: message,
    });
  }
};

// CREATE VTProduct
export const createVTProduct =
  (name, price, description, countInStock, filename) =>
  async (dispatch) => {
    try {
      dispatch({ type: VTPRODUCT_CREATE_REQUEST });

     

      const requestData = {
        name,
        price,
        description,
        countInStock,
        filename,
      };

      const response = await axios.post(
        `https://frontside.veetoore.store/api/vtproducts/`,
        requestData,
      );

      dispatch({ type: VTPRODUCT_CREATE_SUCCESS, payload: response.data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized") {
        dispatch(logout());
      }
      dispatch({
        type: VTPRODUCT_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT VTProduct
export const editVTProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: VTPRODUCT_EDIT_REQUEST });
    const { data } = await axios.get(`https://frontside.veetoore.store/api/vtproducts/${id}`);
    dispatch({ type: VTPRODUCT_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: VTPRODUCT_EDIT_FAIL,
      payload: message,
    });
  }
};

// UPDATE VTProduct
export const updateVTProduct = (vtProduct) => async (dispatch) => {
  try {
    dispatch({ type: VTPRODUCT_UPDATE_REQUEST });

    ;

    const { data } = await axios.put(
      `https://frontside.veetoore.store/api/vtproducts/${vtProduct._id}`,
      vtProduct,
    );

    dispatch({ type: VTPRODUCT_UPDATE_SUCCESS, payload: data });
    dispatch({ type: VTPRODUCT_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized") {
      dispatch(logout());
    }
    dispatch({
      type: VTPRODUCT_UPDATE_FAIL,
      payload: message,
    });
  }
};
